<template>
  <div id="print">
    <div>
      <h1 class="h2">博立图文快印结算单</h1>
    </div>

    <el-row :gutter="10">
      <el-col :span="6">
        <!-- <i class="el-icon-user-solid"></i> -->
        客户名称: {{ order.customerName }}
      </el-col>

      <el-col :span="6">
        <!-- <i class="el-icon-phone"></i> -->
        联系电话: {{ order.phone }}
      </el-col>
      <el-col :span="4">
        <!-- <i class="el-icon-phone"></i> -->
        联系人: {{ order.contact_person }}
      </el-col>
      <el-col :span="8">
        <!-- <i class="el-icon-phone"></i> -->
        项目名称: {{ order.name }}
      </el-col>
    </el-row>
    <p>
      <el-row :gutter="10">
        <el-col :span="10">
          <!-- <i class="el-icon-user-solid"></i> -->
          联系地址: {{ order.address }}
        </el-col>

        <el-col :span="6">
          <!-- <i class="el-icon-phone"></i> -->
          单据编号: BL00000{{ order.id }}
        </el-col>
        <el-col :span="8"> 订单创建时间: {{ order.createdAt }} </el-col>
      </el-row>
    </p>

    <!-- <i class="el-icon-star-off"></i> -->
    <!-- <p>制作要求: {{ order.need }}</p> -->

    <table border="1" class="table" v-if="true">
      <tr>
        <td>项目名称</td>
        <td>份数</td>
        <td>数量</td>
        <td>单位</td>
        <td>单价</td>
        <td>小计</td>
        <td>备注</td>
      </tr>
      <tr v-for="i in order.orderItem_list" :key="i">
        <td>{{ i.title }}</td>
        <td style="width: 60px">{{ i.copies }}</td>
        <!-- <span v-if="i.unit != null && i.unit != ''">/ </span>{{i.unit}} -->
        <td style="width: 60px">{{ i.count }}</td>
        <td style="width: 60px">{{ i.unit }}</td>
        <td style="width: 80px">{{ i.price.toFixed(2) }}元</td>
        <td style="width: 80px">{{ (i.copies * i.count * i.price).toFixed(2) }}元</td>
        <td>{{ i.need }}</td>
      </tr>

    </table>

    <el-table :data="order.orderItem_list" v-if="false">
      <el-table-column fixed label="序号" width="70">
        <template #default="scope">
          {{ scope.$index }}
        </template>
      </el-table-column>
      <el-table-column fixed prop="title" label="项目名称" min-width="120" />
      <el-table-column prop="copies" label="份数" width="100" />
      <el-table-column prop="count" label="单位" width="100" />
      <el-table-column prop="price" label="单价" width="100" />
      <el-table-column prop="price" label="金额" width="100" />
      <el-table-column prop="price" label="备注" min-width="120" />
    </el-table>

    <el-row :gutter="10" class="money">
      <el-col :span="6"> 合计金额(大写): {{ convertCurrency2(order.amount) }} </el-col>

      <el-col :span="7"> 合计金额(小写): {{ order.amount.toFixed(2) }}元 </el-col>
      <el-col :span="6">
        结算方式:
        <div class="square" v-if="order.isPay == 0"></div>

        <span v-if="order.pay_mode == 0 || order.isPay == 0"> 会员卡</span>
        <div class="square" v-if="order.isPay == 0"></div>

        <span v-if="order.pay_mode == 1 || order.isPay == 0"> 现金</span>
      </el-col>

      <el-col :span="5"> 付款状态: {{ order.status }} <span class="el-icon-check"></span></el-col>
    </el-row>

    <el-row :gutter="10" class="money">
      <el-col :span="8"> 门店地址:广州市天河区博立图文快印 </el-col>
      <el-col :span="6"> 联系QQ:1041456587 </el-col>
      <el-col :span="6"> 联系电话:13697429925 </el-col>
    </el-row>

    <el-row :gutter="10" class="money">
      <el-col :span="15"> 工单经手: </el-col>
      <el-col :span="5"> 客户签字: </el-col>
    </el-row>
  </div>
</template>

<script>


export default {
  name: "orderPrint",
  props: ["id", "order"],
  components: {},
  data () {
    return {

    };
  },
  created () {
    console.log(this.order)
  },
  methods: {
    // 返回大写数字
    convertCurrency2 (money) {
      //汉字的数字
      var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖');
      //基本单位
      var cnIntRadice = new Array('', '拾', '佰', '仟');
      //对应整数部分扩展单位
      var cnIntUnits = new Array('', '万', '亿', '兆');
      //对应小数部分单位
      var cnDecUnits = new Array('角', '分', '毫', '厘');
      //整数金额时后面跟的字符
      var cnInteger = '整';
      //整型完以后的单位
      var cnIntLast = '元';
      //最大处理的数字
      var maxNum = 999999999999999.9999;
      //金额整数部分
      var integerNum;
      //金额小数部分
      var decimalNum;
      //输出的中文金额字符串
      var chineseStr = '';
      //分离金额后用的数组，预定义
      var parts;
      if (money == '') { return ''; }
      money = parseFloat(money);
      if (money >= maxNum) {
        //超出最大处理数字
        return '';
      }
      if (money == 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger;
        return chineseStr;
      }
      //转换为字符串
      money = money.toString();
      if (money.indexOf('.') == -1) {
        integerNum = money;
        decimalNum = '';
      } else {
        parts = money.split('.');
        integerNum = parts[0];
        decimalNum = parts[1].substr(0, 4);
      }
      //获取整型部分转换
      if (parseInt(integerNum, 10) > 0) {
        var zeroCount = 0;
        var IntLen = integerNum.length;
        for (var i = 0; i < IntLen; i++) {
          var n = integerNum.substr(i, 1);
          var p = IntLen - i - 1;
          var q = p / 4;
          var m = p % 4;
          if (n == '0') {
            zeroCount++;
          } else {
            if (zeroCount > 0) {
              chineseStr += cnNums[0];
            }
            //归零
            zeroCount = 0;
            chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
          }
          if (m == 0 && zeroCount < 4) {
            chineseStr += cnIntUnits[q];
          }
        }
        chineseStr += cnIntLast;
      }
      //小数部分
      if (decimalNum != '') {
        var decLen = decimalNum.length;
        for (var i = 0; i < decLen; i++) {
          var n = decimalNum.substr(i, 1);
          if (n != '0') {
            chineseStr += cnNums[Number(n)] + cnDecUnits[i];
          }
        }
      }
      if (chineseStr == '') {
        chineseStr += cnNums[0] + cnIntLast + cnInteger;
      } else if (decimalNum == '') {
        chineseStr += cnInteger;
      }
      return chineseStr;
    },

  }
};
</script>

<style lang="less" >
// #print{
//     width: 1000px;
// }
#print {
  .h2 {
    margin: 30px;
    margin-left: 260px;
  }
  .table {
    border-collapse: collapse;
    width: 800px;
    td {
      padding: 6px;
      text-align: center;
    }
  }
  p {
    margin: 10px 0px;
  }
  // 选中所有单元格
  #app .el-table td,
  .el-table th {
    padding: 2px 0 !important;
  }
  .money {
    margin-top: 15px;
  }

  .square {
    width: 10px;
    height: 10px;
    border: solid 1px;
    display: inline-block;
    margin-left: 10px;
  }

  .el-table--large .el-table__cell {
    padding: 5px 0px !important;
  }
}
</style>
