<template>
  <div id="OrderCenter">
      <el-card>
          <div >
              <el-button @click="clickAll('MarkAsPrinted')">未发货订单设置为开始打印</el-button>

              <el-button @click="clickAll('MarkAsPrint')">未发货订单设置为未打印</el-button>
          </div>

      </el-card>

  </div>
</template>
  
<script setup >
import { onMounted, onUpdated, reactive, ref, watch } from 'vue'
import Api from '@/network'
import OrderInfoDetail from "./adminComponents/orderInfoDetail"
import { request } from '@/network/request'
import { useRouter } from 'vue-router'
import OrderPrint from "./Order-print"

const initData = ref(JSON.parse(localStorage.getItem('initData')))
const clickAll = (type) => {
  Api.request({
      url: '/admin/order/adminDataHandle',
      method: 'post',
      data: {
          type: type
      }
  })
}

</script>
  
  

<style lang="less"></style>
  